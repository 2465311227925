import React, { useMemo } from 'react';

import DataTable from 'react-data-table-component'
import FilterComponent from './FilterComponent'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

const Table = (props) => {
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    table: {
        style:{
            backgroundColor: "red"
        }
    },
    headCells: {
      style: {
        fontSize: '14px', // override the cell padding for head cells
        fontWeight: '600',
        color: '#a1a5b7'
      },
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.title,
      cell: (row) => (
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {row.title}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Fecha Inicio',
      selector: (row) => row.title,
      cell: (row) => (
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {row.init_date.toDate().toLocaleDateString()}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Fecha Fin',
      selector: (row) => row.title,
      cell: (row) => (
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {row.limit_date.toDate().toLocaleDateString()}
        </a>
      ),
      sortable: true,
    },


    {
      name: '',
      button: true,
      cell: (row) => (
        <>
          <Link
            to={`/surveys/edit/${row.id}`}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>

          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              props.click(row.id)
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        </>
      ),
    },
  ]

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    (item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  )

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      customStyles={customStyles}
      paginationComponentOptions={paginationComponentOptions}
    />
  )
}

export default Table
