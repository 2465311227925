/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db, storage} from '../../config/firebase'
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom'
import {ref} from 'firebase/storage'
import doFileUpload from '../../components/UploadFileComponent.js'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título obligatorio'),
})


const EditIncident: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

	const goBack = () => {
		navigate(-1);
	}

  const intl = useIntl()

  interface IdIncidenceDetails {
    title: string
    body: string
    status: boolean
    response: string
    imageResolved: File[]
    videoResolved: File[]
  }

  const [incident, setincident] = useState([] as any)
  const [listImages, setListImages] = useState([] as any)
  const [listVideos, setListVideos] = useState([] as any)
  const [listImagesResolved, setListImagesResolved] = useState([] as any)
  const [listVideosResolved, setListVideosResolved] = useState([] as any)
  const [resolved, setResolved] = useState(false)
  const params = useParams()

  useEffect(() => {
    const getincident = async () => {
      try {
        const arrayImages = []
        const arrayVideos = []
        const arrayImagesResolved = []
        const arrayVideosResolved = []
        const docRef = doc(db, 'Communities', currentUser.community_id, 'Incidents', params.id)
        const docSnap = await getDoc(docRef)
        const incident = docSnap.data()

        setincident(incident)

        incident.resolved ? setResolved(true) : setResolved(false)

        incident.media?.image.map((value, index) => {
          arrayImages.push({value, index})
        })
        setListImages(arrayImages)

        incident.media?.video.map((value, index) => {
          arrayVideos.push({value, index})
        })
        setListVideos(arrayVideos)

        incident.resolved?.media?.image.map((value, index) => {
          arrayImagesResolved.push({value, index})
        })
        setListImagesResolved(arrayImagesResolved)

        incident.resolved?.media?.video.map((value, index) => {
          arrayVideosResolved.push({value, index})
        })

        setListVideosResolved(arrayVideosResolved)
      } catch (err) {
        console.error(err)
      }
    }

    getincident()
  }, [])


  const initialValues: IdIncidenceDetails = {
    title: incident.title,
    body: incident.body,
    status: incident.resolved?.read,
    response: incident.resolved?.response,
    imageResolved: incident.resolved?.media?.imageResolved || null,
    videoResolved: incident.resolved?.media?.videoResolved || null,
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdIncidenceDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      const images = []
      if (values.imageResolved) {
        for (const imageFile of values.imageResolved) {
          const storageRefImages = ref(
            storage,
            `/${currentUser.community_id}/incident/${incident.id}/media/image/${imageFile.name}`
          )
          images.push(await doFileUpload(storageRefImages, imageFile))
        }
      }

      const videos = []
      if (values.videoResolved) {
        for (const videoFile of values.videoResolved) {
          const storageRefVideos = ref(
            storage,
            `/${currentUser.community_id}/incident/${incident.id}/media/video/${videoFile.name}`
          )
          videos.push(await doFileUpload(storageRefVideos, videoFile))
        }
      }

      setLoading(false)

      setTimeout(() => {
        if (values.status) {
          setDoc(
            doc(db, `Communities/${currentUser.community_id}/Incidents/`, params.id),
            {
              title: values.title,
              body: values.body,
              isRead: true,
              resolved: {
                date: serverTimestamp(),
                read: true,
                response: values.response,
                media: {
                  image: images,
                  video: videos,
                },
              },
            },
            {merge: true}
          )
        } else {
          updateDoc(doc(db, `Communities/${currentUser.community_id}/Incidents/`, params.id), {
            title: values.title,
            body: values.body,
            isRead: true,
          })
        }

        setLoading(false)
        navigate('/incidents')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Incidencia'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Incidencia</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={resolved}
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Descripción</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  disabled={resolved}
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps('body')}
                ></textarea>
              </div>
            </div>

            <h4 className='my-10'>Documentos de la incidencia</h4>

            {listImages?.map((img) => (
              <div key={img.id}>
                <img src={img.value} className='img-thumbnail w-200px' alt={img.value} />
              </div>
            ))}

            {listVideos?.map((video) => (
              <div key={video.id}>
                <video width='480' height='320' controls>
                  <source src={video.value} type='video/mp4' />
                </video>
              </div>
            ))}

            <h4 className='my-10'>Respuesta Incidencia</h4>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Estado
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  disabled={resolved}
                  className='form-select form-select-solid'
                  {...formik.getFieldProps('status')}
                >
                  <option value=''>Seleccionar opción..</option>
                  <option value='false'>Pendiente</option>
                  <option value='true'>Resuelta</option>
                </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>
                Respuesta Incidencia
              </label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  disabled={resolved}
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps('response')}
                ></textarea>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Imagen</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={resolved}
                  type='file'
                  multiple
                  name='imageResolved'
                  className='form-control form-control-lg form-control-solid mb-3'
                  placeholder='Imagen'
                  accept='image/*'
                  onChange={(e) => formik.setFieldValue('imageResolved', e.currentTarget.files)}
                />

                {listImagesResolved?.map((img) => (
                  <div key={img.id}>
                    <img src={img.value} className='img-thumbnail w-200px' alt={img.value} />
                  </div>
                ))}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Vídeo</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={resolved}
                  type='file'
                  multiple
                  name='videoResolved'
                  className='form-control form-control-lg form-control-solid mb-0'
                  placeholder='Vídeo'
                  accept='video/*'
                  onChange={(e) => formik.setFieldValue('videoResolved', e.currentTarget.files)}
                />
                {listVideosResolved?.map((video) => (
                  <div key={video.id}>
                    <video width='480' height='320' controls>
                      <source src={video.value} type='video/mp4' />
                    </video>
                  </div>
                ))}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {!resolved && (
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Actualizar Incidencia'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Guardando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
              {resolved && (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={goBack}
                >
                  Volver
                </button>
              )}
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditIncident}
