/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import {collection, doc, getDoc, getDocs, setDoc} from 'firebase/firestore'
import {useNavigate, useParams} from 'react-router-dom'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título del pago obligatorio'),
  category_id: Yup.string().required('Categoría obligatoria'),
})

const EditInformation: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  interface IdInformationDetails {
    title: string
    category_id: string
    body: string
    video: string
    image: string
  }

  const [information, setinformation] = useState([] as any)
  const [categoriesList, setcategoriesList] = useState([] as any)
  const [listImages, setListImages] = useState([] as any)
  const [listVideos, setListVideos] = useState([] as any)

  const params = useParams()

  useEffect(() => {
    const getcategoriesList = async () => {
      try {
        await getDocs(
          collection(db, 'Communities', currentUser.community_id, 'CategoriesInformation')
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setcategoriesList(newData)
          console.log(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getcategoriesList()
  }, [])

  //Get info information
  useEffect(() => {
    const getinformation = async () => {
      try {
        const arrayImages = []
        const arrayVideos = []
        const docRef = await doc(
          db,
          'Communities',
          currentUser.community_id,
          'Information',
          params.id
        )
        const docSnap = await getDoc(docRef)

        const information = docSnap.data()
        setinformation(information)

        docSnap.data().media.image.map((value, index) => {
          arrayImages.push({value, index})
        })

        docSnap.data().media.video.map((value, index) => {
          arrayVideos.push({value, index})
        })

        setListImages(arrayImages)
        setListVideos(arrayVideos)
      } catch (err) {
        console.error(err)
      }
    }

    getinformation()
  }, [])

  const initialValues: IdInformationDetails = {
    title: information.title,
    category_id: information.category_id,
    body: information.body,
    image: information.media?.image,
    video: information.media?.video,
  }

  const [data, setData] = useState<IdInformationDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IdInformationDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdInformationDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        console.log(values)

        setDoc(
          doc(db, `Communities/${currentUser.community_id}/Information/`, params.id),
          {
            title: values.title,
            body: values.body,
            category_id: values.category_id,
          },
          {merge: true}
        )

        setLoading(false)
        navigate('/information')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar Informaciones'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar Informaciones</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Categoría
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='category_id'
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.category_id}
                >
                  <option value=''>Seleccionar categoría..</option>
                  {categoriesList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
                {formik.touched.category_id && formik.errors.category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.category_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Descripción</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  name='body'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps('body')}
                ></textarea>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Imagen
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  name='image'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Imagen'
                  accept='image/*'
                  onChange={(e) => formik.setFieldValue('image', e.currentTarget.files[0])}
                />

                <div className='d-flex'>
                  {listImages?.map((img) => (
                    <div key={img.id} className="m-3">
                      <img src={img.value} className='img-thumbnail w-200px' alt={img.value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Vídeo
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  name='video'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Vídeo'
                  accept='video/*'
                  onChange={(e) => formik.setFieldValue('video', e.currentTarget.files[0])}
                />

                <div className='d-flex'>
                  {listVideos?.map((video) => (
                    <div key={video.id} className='m-3'>
                      <video width='480' height='320' controls>
                        <source src={video.value} type='video/mp4' />
                      </video>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Modificar Información'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditInformation}
