/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {auth, db} from '../../config/firebase'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom'
import {createUserWithEmailAndPassword} from 'firebase/auth'

const userDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Nombre obligatorio'),
  email: Yup.string().required('Email obligatorio'),
  community_id: Yup.string().required('Comunidad obligatoria'),
  password: Yup.string()
    .min(6, 'Mínimo 6 caracatres')
    .max(50, 'Máximo 50 caracteres')
    .required('Password obligatorio'),
  passwordConfirmation: Yup.string()
    .min(6, 'Mínimo 6 caracatres')
    .max(50, 'Máximo 50 caracteres')
    .required('Password obligatorio')
    .oneOf([Yup.ref('password'), null], 'Los passwords no coinciden'),
})

const NewAdmin: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [currentAdminsCommunities, setcurrentAdminsCommunities] = useState([] as any)
  const [comunitiesList, setcomunitiesList] = useState([] as any)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getcurrentAdminsCommunities = async () => {
      try {
        const q = query(
          collection(
            db,
            'Administrador'
          ),
          where('role', '==', 0)
        )
        const currentComunities = []
        await getDocs(q).then((querySnapshot) => {
          querySnapshot.docs.map((doc) => {
            currentComunities.push(doc.data().community_id)
          })
          setcurrentAdminsCommunities(currentComunities)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getcurrentAdminsCommunities()
  }, [])


  useEffect(() => {
    const getcomunitiesList = async () => {
      try {
        const q = query(
          collection(
            db,
            'Communities',
            currentUser.community_id,
            'Administrador',
            'data',
            'Documents'
          ),
          where('isPublic', '==', true)
        )

        await getDocs(collection(db, 'Communities')).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setcomunitiesList(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getcomunitiesList()
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      role: '',
      email: '',
      community_id: '',
      token: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((res) => {
          const data = {
            name: values.name,
            community_id: values.community_id,
            token: res.user.uid,
            role: 0,

          }
          setLoading(true)
          setTimeout(() => {
            setDoc(doc(db, 'Administrador', values.email), {
              data
            })

            setLoading(false)
            navigate('/admins')
          }, 1000)
        })
        .catch((err) => console.error(err))
    },
  })


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Administrador'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Administrador</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Comunidad
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='community_id'
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.community_id}
                >
                  <option value=''>Seleccionar comunidad..</option>
                  {comunitiesList.map((community) => (
                    !currentAdminsCommunities.includes(community.id) && (
                    <option key={community.id} value={community.id}>
                      {community.community_info.name}
                    </option>
                    )
                  ))}
                </select>
                {formik.touched.community_id && formik.errors.community_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.community_id}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Nombre
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='name'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nombre'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                E-mail
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='email'
                  name='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='E-mail'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Password
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='password'
                  name='password'
                  id='password'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Pasword'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Confirmar Password
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='password'
                  id='confirmpassword'
                  name='confirmpassword'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Confirmar Password'
                  {...formik.getFieldProps('passwordConfirmation')}
                />

                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir Administrador'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewAdmin}
