import { useEffect, useState } from 'react';
import {KTSVG} from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import {useAuth} from '../../modules/auth';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../config/firebase';

const FilterComponent = (
  {filterText, onFilter, onClear} // <> //   <Input //     id="search"
) => {
  const {currentUser,logout} = useAuth()


  const [usersList, setusersList] = useState([])


  useEffect (() => {
    const getusersList = async () => {
      try {
        await getDocs (
          collection (db, 'Communities', currentUser.community_id, 'Users')
        ).then (querySnapshot => {
          const newData = querySnapshot.docs.map (doc => ({
            ...doc.data (),
            id: doc.id,
          }));
          setusersList (newData);
          console.log (newData);
        });
      } catch (err) {
        console.error (err);
      }
    };

    getusersList ();
  }, []);

  return (
    <div className="card-header border-0 p-0 m-0 w-100">

      <div className="d-flex align-items-center">
        <div className="card-title m-0 my-0 p-0">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Filtrar..."
              onChange={onFilter}
            />
          </div>
          {/* end::Search */}

          <div className="mx-5">
            <select
              name="user"
              className="form-select form-select-solid w-350px"
              value={filterText}
              onChange={onFilter}
            >
              <option value="">Seleccionar usuario..</option>
              {usersList.map (user => (
                <option key={user.id} value={user.id}>
                  {user.personal_information.name}
                  {' '}
                  {user.personal_information.surname}
                </option>
              ))}
            </select>
          </div>

        </div>
      </div>
      {/* begin::Card toolbar */}
      <div className="card-toolbar">
        <Link
          to="/notices/create"
          className="btn btn-primary align-self-center"
        >
          Añadir notificación
        </Link>
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export default FilterComponent;
