/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom'
import GetusersList from '../../services/getusersList'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título del pago obligatorio'),
  user: Yup.string().required('Usuario obligatorio'),
  amount: Yup.string().required('Precio obligatorio'),
})

const EditPayment: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  const intl = useIntl()

  interface IdPaymentDetails {
    title: string
    user: string
    amount: string
    description: string
    init_date: string
    limit_date: string
  }

  const [payment, setpayment] = useState([] as any)
  const [usersList, setusersList] = useState([] as any)
  const [started, setStarted] = useState(false)

  const params = useParams()

  //Get info payment
  useEffect(() => {
    const getpayment = async () => {
      try {
        const docRef = await doc(
          db,
          'Communities',
          currentUser.community_id,
          'Users',
          params.user,
          'Payments',
          params.id
        )
        const docSnap = await getDoc(docRef)

        const payment = docSnap.data()

        setpayment(payment)

        payment.limit_date?.toDate().getTime() - new Date().getTime() > 0
          ? setStarted(false)
          : setStarted(true)
      } catch (err) {
        console.error(err)
      }
    }

    getpayment()
  }, [])

  //Get all users
  useEffect(() => {
    GetusersList()
  }, [])

  const initialValues: IdPaymentDetails = {
    title: payment.title,
    user: params.user,
    amount: payment.amount,
    description: payment.description,
    init_date: payment.init_date?.toDate().toISOString().slice(0, 10),
    limit_date: payment.limit_date?.toDate().toISOString().slice(0, 10),
  }

  const [data, setData] = useState<IdPaymentDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IdPaymentDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdPaymentDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        console.log(values)

        setDoc(
          doc(
            db,
            `Communities/${currentUser.community_id}/Users/${values.user}/Payments/`,
            params.id
          ),
          {
            title: values.title,
            description: values.description,
            amount: values.amount,
            init_date: new Date(values.init_date),
            limit_date: new Date(values.limit_date),
          },
          {merge: true}
        )

        setLoading(false)
        navigate('/payments')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar Pagos'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar Pago</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Usuario
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  disabled={started}
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.user}
                >
                  <option value=''>Seleccionar usuario..</option>
                  {usersList.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.personal_information.name} {user.personal_information.surname}
                    </option>
                  ))}
                </select>
                {formik.touched.user && formik.errors.user && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.user}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Descripción</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  disabled={started}
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps('description')}
                ></textarea>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Fecha Inicio
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fecha Inicio'
                  {...formik.getFieldProps('init_date')}
                />
                {formik.touched.init_date && formik.errors.init_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.init_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Fecha Fin
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fecha Fin'
                  {...formik.getFieldProps('limit_date')}
                />
                {formik.touched.limit_date && formik.errors.limit_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.limit_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Precio
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='number'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Precio'
                  {...formik.getFieldProps('amount')}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.amount}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {payment.limit_date?.toDate().getTime() - new Date().getTime() > 0 && (
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Modificar pago'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Guardando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
              {started && (
                <button type='button' className='btn btn-primary' onClick={goBack}>
                  Volver
                </button>
              )}
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditPayment}
