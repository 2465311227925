/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'



const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título del pago obligatorio'),
})

const NewCategoryDocument: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate=useNavigate()

  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: docDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        addDoc(collection(db, 'Communities', currentUser.community_id, 'CategoriesDocument'), {
          title: values.title
        })

        setLoading(false)
        navigate("/documents/categories");
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Categorías'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Categoría</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>


            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewCategoryDocument}
