import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import { useAuth } from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {

  const {currentUser, logout} = useAuth()

  const {config} = useLayout()
  return (
    <div className='app-navbar-item mt-3'>
        Bienvenido {currentUser.first_name}
        <a className="btn btn-light-primary font-weight-bold mx-5" onClick={logout}>Salir</a>
    </div>
  )
}

export {Navbar}
