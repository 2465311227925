/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers';
import {PageTitle} from '../../../_metronic/layout/core'
import {getDocs, collection, deleteDoc, doc} from 'firebase/firestore'
import { useEffect, useState } from 'react';
import {db} from '../../config/firebase'
import { useAuth } from '../../modules/auth'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'


const CategoriesInformationWrapper: FC = () => {

  const {currentUser,logout} = useAuth()

  const intl = useIntl()

  const [rows, setrows] = useState([] as any)

  const deleteDocument = async (id) => {
    Swal.fire({
      title: '¿Deseas eliminarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Eliminado!', 'Se ha eliminado correctamente.', 'success').then(function () {
          deleteDoc(
            doc(db, `Communities/${currentUser.community_id}/CategoriesInformation/${id}`)
          )

          setrows(rows.filter((item) => item.id !== id))
        })
      }
    })
  }


  useEffect(() => {
    const getincidentsList = async () => {
      try {

      await getDocs(collection(db, "Communities", currentUser.community_id, "CategoriesInformation"))
      .then((querySnapshot)=>{
          const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
              setrows(newData);
          console.log(newData);
      })

      } catch (err) {
        console.error(err);
      }
    }

    getincidentsList()

  },[])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Categorías'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Categorías</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a payment'
          >

            <Link to='/information/categories/create' className='btn btn-primary align-self-center'>
            Nueva Categoría
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Nombre</th>

                  <th className='min-w-100px text-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {row.title}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>

                      <Link to={`/information/categories/edit/${ row.id }`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                       <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </Link>

                        <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => {
                          deleteDocument(row.id)
                        }}
                      >
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                      </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {CategoriesInformationWrapper}
