/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom'
import GetusersList from '../../services/getusersList'


const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título del pago obligatorio'),
  user: Yup.string().required('Usuario obligatorio'),
})

const EditNotice: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()


  const params = useParams();

  interface IdIncidenceDetails {
    title: string
    body: string
    user: string

  }
  const [notice, setnotice] = useState([] as any)
  const [listImages, setListImages] = useState([] as any)
  const [listVideos, setListVideos] = useState([] as any)
  const [usersList, setusersList] = useState([] as any)

  useEffect(() => {
    GetusersList()
  }, [])

  useEffect(() => {
    const getnotice = async () => {
      try {

        const arrayImages = []
        const arrayVideos = []

        const docRef = await doc(db, 'Communities', currentUser.community_id, 'Users',params.user,'Notices',params.id)
        const docSnap = await getDoc(docRef)

       const notice = docSnap.data()
       setnotice(notice)

       docSnap.data().media.image.map((value, index) => {
        arrayImages.push({value, index})
      })

      docSnap.data().media.video.map((value, index) => {
        arrayVideos.push({value, index})
      })

      setListImages(arrayImages)
      setListVideos(arrayVideos)

      } catch (err) {
        console.error(err)
      }
    }

    getnotice()
  }, [])

  const initialValues: IdIncidenceDetails = {
    title: notice.title,
    body: notice.body,
    user: params.user

  }

  const [data, setData] = useState<IdIncidenceDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IdIncidenceDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {

        setDoc(
          doc(db, `Communities/${currentUser.community_id}/Users/${values.user}/Notices`, params.id),
          {
            title: values.title,
            body: values.body,
            isRead: true,
          },
          {merge: true}
        )

        setLoading(false)
        navigate("/notices");
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar notificación'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar notificación</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Usuario
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  className='form-select form-select-solid'
                  {...formik.getFieldProps('user')}
                >
                  <option value=''>Seleccionar usuario..</option>
                  {usersList.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.personal_information.name} {user.personal_information.surname}
                    </option>
                  ))}
                </select>
                {formik.touched.user && formik.errors.user && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.user}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Descripción</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps('body')}
                ></textarea>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Imagen</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='image'
                  className='form-control form-control-lg form-control-solid mb-3'
                  placeholder='Imagen'
                  accept='image/*'
                  onChange={(e) => formik.setFieldValue('image', e.currentTarget.files)}
                />

                {listImages?.map((img) => (
                  <div key={img.id}>
                    <img src={img.value} className='img-thumbnail w-200px' alt={img.value} />
                  </div>
                ))}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Vídeo</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='video'
                  className='form-control form-control-lg form-control-solid mb-0'
                  placeholder='Vídeo'
                  accept='video/*'
                  onChange={(e) => formik.setFieldValue('video', e.currentTarget.files)}
                />
                {listVideos?.map((video) => (
                  <div key={video.id}>
                    <video width='640' height='480' controls>
                      <source src={video.value} type='video/mp4' />
                    </video>
                  </div>
                ))}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Modificar notificación'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditNotice}
