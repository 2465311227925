import { collection, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useAuth } from "../modules/auth";
import { db } from "../config/firebase";

async function GetusersList() {
  const { currentUser, logout } = useAuth();
  const [usersList, setusersList] = useState([] as any)

  try {
    await getDocs(
      collection(db, "Communities", currentUser.community_id, "Users")
    ).then((querySnapshot) => {
      let recommendedTags = [{ value: "all", label: "Todos los usuarios" }];
      querySnapshot.forEach(function (doc) {
        recommendedTags.push({
          value: doc.id,
          label:
            doc.data().personal_information.name +
            " " +
            doc.data().personal_information.surname,
        });
      });
      setusersList(recommendedTags);
    });
  } catch (err) {
    console.error(err);
  }
}

export default GetusersList;
