/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { currentUser, logout } = useAuth();

  console.log(currentUser);
  return (
    <>
      {currentUser.roles === 1 && (
        <>
          <SidebarMenuItem
            to="/admins"
            icon="/media/icons/duotune/communication/com006.svg"
            title="Administradores"
            fontIcon="bi-layers"
          />

          <SidebarMenuItem
            to="/communities"
            icon="/media/icons/duotune/communication/com006.svg"
            title="Comunidades"
            fontIcon="bi-layers"
          />
        </>
      )}

      {currentUser.roles === 0 && (
        <>
          <SidebarMenuItem
            to="/users"
            icon="/media/icons/duotune/communication/com006.svg"
            title="Usuarios"
            fontIcon="bi-layers"
          />

          <SidebarMenuItem
            to="/incidents"
            icon="/media/icons/duotune/medicine/med008.svg"
            title="Incidencias"
            fontIcon="bi-layers"
          />

          <SidebarMenuItem
            to="/payments"
            icon="/media/icons/duotune/finance/fin009.svg"
            title="Pagos"
            fontIcon="bi-layers"
          />

          <SidebarMenuItem
            to="/surveys"
            icon="/media/icons/duotune/communication/com007.svg"
            title="Encuestas"
            fontIcon="bi-layers"
          />

          <SidebarMenuItem
            to="/notices"
            icon="/media/icons/duotune/communication/com004.svg"
            title="Notificaciones"
            fontIcon="bi-layers"
          />

          <SidebarMenuItemWithSub
            to="/documents"
            title="Documentos"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/files/fil012.svg"
          >
            <SidebarMenuItem
              to="/documents"
              title="Documentos"
              fontIcon="bi-layers"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/documents/categories"
              title="Categorías"
              fontIcon="bi-layers"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>


          <SidebarMenuItemWithSub
            to="/information"
            title="Información Comunidad"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen016.svg"
          >
            <SidebarMenuItem
              to="/information"
              title="Información Comunidad"
              fontIcon="bi-layers"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/information/categories"
              title="Categorías"
              fontIcon="bi-layers"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>

        </>
      )}
    </>
  );
};

export { SidebarMenuMain };
