/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {getDocs, collection, deleteDoc, doc, getDoc} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {auth, db} from '../../config/firebase'
import {useAuth} from '../../modules/auth'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import Table from './DataTable'
import { deleteUser, getAuth } from 'firebase/auth'




const UsersWrapper: FC = () => {
  const {currentUser,logout} = useAuth()

  const [rows, setrows] = useState([] as any)

  const intl = useIntl()

  const deleteDocument = async (id) => {

    // const docRef = doc(db,'Users',id)
    // const docSnap = await getDoc(docRef)
    // const user = docSnap.data()


    Swal.fire({
      title: '¿Deseas eliminarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Eliminado!', 'Se ha eliminado correctamente.', 'success').then(function () {

         
          // deleteUser([user.uid])
          // .then((deleteUsersResult) => {
          //   console.log(`Successfully deleted ${deleteUsersResult.successCount} users`);
          //   console.log(`Failed to delete ${deleteUsersResult.failureCount} users`);
          //   deleteUsersResult.errors.forEach((err) => {
          //     console.log(err.error.toJSON());
          //   });
          // })
          // .catch((error) => {
          //   console.log('Error deleting users:', error);
          // });
         
          // deleteCollection(db,`Communities/${currentUser.community_id}/Users/${id}/Documents`,2)
          // deleteCollection(db,`Communities/${currentUser.community_id}/Users/${id}/Surveys`,2)
          // deleteCollection(db,`Communities/${currentUser.community_id}/Users/${id}/Notices`,2)
          // deleteCollection(db,`Communities/${currentUser.community_id}/Users/${id}/Payments`,2)
          deleteDoc(doc(db, `Users/${id}`))
          deleteDoc(doc(db, `Communities/${currentUser.community_id}/Users/${id}`))
          setrows(rows.filter((item) => item.id !== id))
        })
      }
    })
  }

  useEffect(() => {
    if(currentUser.roles === 1)logout()

    getrowsList()
  }, [])

  const getrowsList = async () => {
    try {
      await getDocs(collection(db, 'Communities', currentUser.community_id, 'Users')).then(
        (querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setrows(newData)
        }
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Usuarios'})}</PageTitle>

      <div className={`card`}>

        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>

          <Table data={rows} click={deleteDocument} />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {UsersWrapper}
