/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import {doc, getDoc, setDoc} from 'firebase/firestore'
import {useNavigate, useParams} from 'react-router-dom'

const docDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Nombre obligatorio'),
  surname: Yup.string().required('Apellidos obligatorio'),
  email: Yup.string().required('E-mail obligatorio'),
  phone: Yup.string().required('Teléfono obligatorio'),
})
const EditUser: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [user, setuser] = useState([] as any)
  const params = useParams()

  interface IdUserDetails {
    email: string
    name: string
    surname: string
    phone: string
    building: string
    door: string
    floor: string
    stair: string
  }

  const getuser = async () => {
    try {
      const docRef = doc(db, 'Communities', currentUser.community_id, 'Users', params.id)
      const docSnap = await getDoc(docRef)
      const user = docSnap.data()
      setuser(user)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getuser()
  }, [])

  let initialValues: IdUserDetails = {
    email: user.personal_information?.email || '',
    name: user.personal_information?.name || '',
    surname: user.personal_information?.surname || '',
    phone: user.personal_information?.phone || '',
    building: user.personal_information?.house?.building || '',
    door: user.personal_information?.house?.door || '',
    floor: user.personal_information?.house?.floor || '',
    stair: user.personal_information?.house?.stair || '',
  }

  const [data, setData] = useState<IdUserDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IdUserDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdUserDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      console.log("entro")
      setLoading(true)

      setTimeout(() => {
        setDoc(
          doc(db, `Communities/${currentUser.community_id}/Users/`, params.id),
          {
            personal_information: {
              email: values.email,
              name: values.name,
              surname: values.surname,
              phone: values.phone,
              house: {
                building: values.building,
                door: values.door,
                floor: values.floor,
                stair: values.stair,
              },
            },
          },
          {merge: true}
        )

        setLoading(false)
        navigate('/users')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar Usuario'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar Usuario</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
        <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Nombre
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nombre'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Apellidos
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Apellidos'
                  {...formik.getFieldProps('surname')}
                />
                {formik.touched.surname && formik.errors.surname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.surname}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Teléfono
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Teléfono'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                E-mail
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  readOnly
                  type='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='E-mail'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Edificio</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Edificio'
                  {...formik.getFieldProps('building')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Planta</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Planta'
                  {...formik.getFieldProps('floor')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Escalera</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Escalera'
                  {...formik.getFieldProps('stair')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Puerta</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Puerta'
                  {...formik.getFieldProps('door')}
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Actualizar Usuario'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditUser}
