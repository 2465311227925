/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getDocs, collection, deleteDoc, doc} from 'firebase/firestore'
import { useEffect, useState } from 'react';
import {db} from '../../config/firebase'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import Table from './DataTable'

const PaymentsWrapper: FC = () => {
  const {currentUser,logout} = useAuth()

  const [rows, setrows] = useState([] as any)

  const intl = useIntl()

  const deleteDocument = async (user, id) => {
    Swal.fire({
      title: '¿Deseas eliminarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Eliminado!', 'Se ha eliminado correctamente.', 'success').then(function () {
          deleteDoc(doc(db, `Communities/${currentUser.community_id}/Users/${user}/Payments/${id}`))
          setrows(rows.filter((item) => item.id !== id))
        })
      }
    })
  }

  useEffect(() => {
    if(currentUser.roles === 1)logout()

    getrowsList()
  }, [])

  const getrowsList = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, 'Communities', currentUser.community_id, 'Users')
      )
      let allDocs = []
      let allPayments = []
      querySnapshot.forEach((doc) => {
        allDocs.push({...doc.data(), id: doc.id})
      })
      for (const item of allDocs) {
        const querySnap = await getDocs(
          collection(db, `Communities/${currentUser.community_id}/Users/${item.id}/Payments`)
        )
        querySnap.forEach((doc1) => {
          allPayments.push({...doc1.data(), id: doc1.id, user: item.id})
        })
      }
      setrows(allPayments)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Pagos'})}</PageTitle>

      <div className={`card`}>

        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <Table data={rows} click={deleteDocument} />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {PaymentsWrapper}
