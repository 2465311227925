/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getDocs, collection, deleteDoc, doc} from 'firebase/firestore'
import { useEffect, useState } from 'react';
import {db} from '../../config/firebase'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import Table from './DataTable'
import GetusersList from '../../services/getusersList'

const DocumentsWrapper: FC = () => {
  const {currentUser,logout} = useAuth()

  const [rows, setrows] = useState([] as any)
  
  const intl = useIntl()
  const [usersList, setusersList] = useState([] as any)


  const deleteDocument = async (id) => {
    Swal.fire({
      title: '¿Deseas eliminarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Eliminado!', 'Se ha eliminado correctamente.', 'success').then(function () {
          deleteDoc(
            doc(db, `Communities/${currentUser.community_id}/Administrador/data/Documents/${id}`)
          )
          usersList.forEach(async (user) => {
            await deleteDoc(
              doc(db, `Communities/${currentUser.community_id}/Users/${user.id}/Documents/${id}`)
            )
          })
          setrows(rows.filter((item) => item.id !== id))
        })
      }
    })
  }

  useEffect(() => {
    if(currentUser.roles === 1)logout()
    GetusersList()
  }, [])



  useEffect(() => {
    const getincidentsList = async () => {
      try {
        await getDocs(
          collection(
            db,
            'Communities',
            currentUser.community_id,
            'Administrador',
            'data',
            'Documents'
          )
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setrows(newData)
          console.log(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getincidentsList()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Documentos Comunidad'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <Table data={rows} click={deleteDocument} />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {DocumentsWrapper}
