/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getDocs, collection, where, query} from 'firebase/firestore'
import { useEffect, useState } from 'react';
import {db} from '../../config/firebase'
import {useAuth} from '../../modules/auth'
import Table from './DataTable'

const AdminWrapper: FC = () => {
  const {currentUser,logout} = useAuth()

  if(currentUser.roles == 0)logout()

  const [rows, setrows] = useState([] as any)

  const intl = useIntl()

  useEffect(() => {
    getrowsList()
  }, [])

  const getrowsList = async () => {
    try {

      const q = query(collection(
        db,
        'Administrador'
      ), where("role", "==", 0));

      await getDocs(q).then(
        (querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setrows(newData)
        }
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Administradores'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <Table data={rows} />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {AdminWrapper}
