import { getDownloadURL, uploadBytesResumable } from "firebase/storage";



//Handle waiting to upload each file using promise
const doFileUpload = async (storageRef, file) => {
  return new Promise (function (resolve, reject) {
    console.log ('Starting ' + file.name + ' upload...'); // documental

    var task = uploadBytesResumable (storageRef, file);

    //Update progress bar
    task.on (
      'state_changed',
      function progress (snapshot) {
        var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      },
      function error (err) {
        console.log (err);
        reject (err);
      },
      function complete () {
        var downloadURL = getDownloadURL (task.snapshot.ref);
        resolve (downloadURL);
      }
    );
  });
};

export default doFileUpload;
