/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getDocs, collection, deleteDoc, doc} from 'firebase/firestore'
import { useEffect, useState } from 'react';
import {db} from '../../config/firebase'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import Table from './DataTable'


const InformationWrapper: FC = () => {

  const {currentUser,logout} = useAuth()

  const [rows, setrows] = useState([] as any)
  
  const intl = useIntl()

  const deleteDocument = async (id) => {
    Swal.fire({
      title: '¿Deseas eliminarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Eliminado!', 'Se ha eliminado correctamente.', 'success').then(function () {
          deleteDoc(doc(db, `Communities/${currentUser.community_id}/Informations/${id}`))
          setrows(rows.filter((item) => item.id !== id))
        })
      }
    })
  }

  useEffect(() => {
    if(currentUser.roles === 1)logout()

    getrowsList()
  }, [])

  const getrowsList = async () => {
    try {
      await getDocs(collection(db, 'Communities', currentUser.community_id, 'Information')).then(
        (querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setrows(newData)
        }
      )
    } catch (err) {
      console.error(err)
    }
  }



  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Información Comunidad'})}</PageTitle>

      <div className={`card`}>

        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}

            <Table data={rows} click={deleteDocument} />

          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {InformationWrapper}
