/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db, storage} from '../../config/firebase'
import { addDoc, collection, doc, getDocs, serverTimestamp, setDoc } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom'
import { ref } from 'firebase/storage';
import Select from 'react-select'
import doFileUpload from '../../components/UploadFileComponent.js'
import GetusersList from '../../services/getusersList'


function slugify(str) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título obligatorio'),
  category_id: Yup.string().required('Categoría obligatoria'),
})


const NewDocument: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [usersList, setusersList] = useState([] as any)

  const [categoriesList, setcategoriesList] = useState([] as any)

  useEffect(() => {
    const getcategoriesList = async () => {
      try {
        await getDocs(
          collection(db, 'Communities', currentUser.community_id, 'CategoriesDocument')
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setcategoriesList(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getcategoriesList()
  }, [])

  useEffect(() => {
    GetusersList()
  }, [])

  const [loading, setLoading] = useState(false)

  const [progress, setProgress] = useState(0)
  const [selectedImages, setSelectedImages] = useState([])

  const formik = useFormik({
    initialValues: {
      user: [],
      category_id: '',
      title: '',
      name: '',
      document: null,
    },
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      const file = values.document

      const storageRef = ref(
        storage,
        `/${currentUser.community_id}/document/${slugify(values.title)}.pdf`
      )

      const url = await doFileUpload(storageRef, file)

      setLoading(true)
      setTimeout(async () => {
        let usersArray = []
        if (values.user[0]['values'] !== 'all') {
          values.user.forEach((user) => {
            usersArray.push(user['value'])
          })
        }
        //ADD DOCUMENTS TO ADMINISTRADOR
        addDoc(
          collection(
            db,
            'Communities',
            currentUser.community_id,
            'Administrador',
            'data',
            'Documents'
          ),
          {
            title: values.title,
            category_id: values.category_id,
            name: slugify(values.title)+'.pdf',
            isPublic: values.user[0]['value'] === 'all' ? true : false,
            document: url,
            users: usersArray,
            date: serverTimestamp(),
          }
        ).then((docRef) => {
          //ADD DOCUMENTS TO ALL USERS
          if (values.user[0]['value'] === 'all') {
            usersList.forEach(async (document) => {
              await setDoc(
                doc(
                  db,
                  'Communities',
                  currentUser.community_id,
                  'Users',
                  document.value,
                  'Documents',
                  docRef.id
                ),
                {
                  title: values.title,
                  category_id: values.category_id,
                  name: slugify(values.title)+'.pdf',
                  document: url,
                  date: serverTimestamp(),
                }
              )
            })
          } else {
            //ADD DOCUMENT TO SELECTED USERS
            values.user.forEach((user) => {
              setDoc(
                doc(
                  db,
                  'Communities',
                  currentUser.community_id,
                  'Users',
                  user['value'],
                  'Documents',
                  docRef.id
                ),
                {
                  date: serverTimestamp(),
                  title: values.title,
                  category_id: values.category_id,
                  name: slugify(values.title)+'.pdf',
                  document: url,
                }
              )
            })
          }
        })

        setLoading(false)
        navigate('/documents')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Información'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-document align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Información</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Usuario
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <Select
                  isMulti
                  name='user'
                  onChange={(option) => formik.setFieldValue('user', option)}
                  value={formik.values.user}
                  closeMenuOnSelect={false}
                  defaultValue={usersList[1]}
                  options={usersList}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Categoría
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='category_id'
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.category_id}
                >
                  <option value=''>Seleccionar categoría..</option>
                  {categoriesList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
                {formik.touched.category_id && formik.errors.category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.category_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Documento</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  name='document'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Documento'
                  accept='pdf/*'
                  onChange={(e) => formik.setFieldValue('document', e.currentTarget.files[0])}
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewDocument}
