/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db, storage} from '../../config/firebase'
import { addDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom'
import { ref } from 'firebase/storage';
import doFileUpload from '../../components/UploadFileComponent.js'


const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título del pago obligatorio'),
  category_id: Yup.string().required('Categoría obligatoria'),
})



const NewInformation: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [categoriesList, setcategoriesList] = useState([] as any)

  useEffect(() => {
    const getcategoriesList = async () => {
      try {
        await getDocs(
          collection(db, 'Communities', currentUser.community_id, 'CategoriesInformation')
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setcategoriesList(newData)
          console.log(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getcategoriesList()
  }, [])

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      category_id: '',
      title: '',
      body: '',
      image: null,
      video: null,
    },
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      const images = []
      for (const imageFile of values.image) {
        const storageRefImages = ref(
          storage,
          `/${currentUser.community_id}/media/image/${imageFile.name}`
        )
        images.push(await doFileUpload(storageRefImages, imageFile))
      }

      const videos = []
      for (const videoFile of values.video) {
        const storageRefVideos = ref(
          storage,
          `/${currentUser.community_id}/media/video/${videoFile.name}`
        )
        videos.push(await doFileUpload(storageRefVideos, videoFile))
      }

      setTimeout(() => {
        addDoc(collection(db, 'Communities', currentUser.community_id, 'Information'), {
          title: values.title,
          body: values.body,
          category_id: values.category_id,
          date: serverTimestamp(),
          media: {
            image: images,
            video: videos,
          },
        })

        setLoading(false)
        navigate('/information')
      }, 1000)
    },
  })


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Información'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Información</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Categoría
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='category_id'
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.category_id}
                >
                  <option value=''>Seleccionar categoría..</option>
                  {categoriesList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
                {formik.touched.category_id && formik.errors.category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.category_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Descripción
              </label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  className='form-control form-control-solid mb-8'
                  rows={3}
                  name='body'
                  onChange={formik.handleChange}
                  placeholder='Descripción'
                >
                  {formik.values.body}
                </textarea>

                {formik.touched.body && formik.errors.body && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.body}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Imagen</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='image'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Imagen'
                  accept='image/*'
                  onChange={(e) => formik.setFieldValue('image', e.currentTarget.files)}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Vídeo</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='video'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Vídeo'
                  accept='video/*'
                  onChange={(e) => formik.setFieldValue('video', e.currentTarget.files)}
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewInformation}
