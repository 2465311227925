/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db, storage} from '../../config/firebase'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import {ref} from 'firebase/storage'
import doFileUpload from '../../components/UploadFileComponent.js'
import GetusersList from '../../services/getusersList'

function slugify(str) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}



const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título obligatorio'),
  category_id: Yup.string().required('Categoría obligatoria'),
})

const EditDocument: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  interface IdDocumentDetails {
    title: string
    category_id: string
    users: Array<Array<string>>
    document: string
  }

  const [document, setdocument] = useState([] as any)
  const [categoriesList, setcategoriesList] = useState([] as any)
  const [usersList, setusersList] = useState([] as any)

  const params = useParams()

  useEffect(() => {
    const getcategoriesList = async () => {
      try {
        await getDocs(
          collection(db, 'Communities', currentUser.community_id, 'CategoriesDocument')
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setcategoriesList(newData)
          console.log(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getcategoriesList()
  }, [])

  //Get info document
  useEffect(() => {
    const getdocument = async () => {
      try {
        const docRef = doc(
          db,
          'Communities',
          currentUser.community_id,
          'Administrador',
          'data',
          'Documents',
          params.id
        )
        const docSnap = await getDoc(docRef)
        const document = docSnap.data()
        setdocument(document)
      } catch (err) {
        console.error(err)
      }
    }

    getdocument()
  }, [])

  useEffect(() => {
    GetusersList()
  }, [])

  const initialValues: IdDocumentDetails = {
    title: document.title,
    category_id: document.category_id,
    users: document?.users || [],
    document: null,
  }

  const [data, setData] = useState<IdDocumentDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IdDocumentDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdDocumentDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      let url = document.document

      if (values.document) {
        const file = values.document

        const storageRef = ref(
          storage,
          `/${currentUser.community_id}/document/${document.name}`
        )

        url = await doFileUpload(storageRef, file)
      }

      setLoading(true)
      setTimeout(() => {
        let usersArray = []
        if (values.users[0]['value'] !== 'all') {
          values.users.forEach((user) => {
            usersArray.push(user['value'])
          })

          usersList.forEach((user) => {
            
            if (user['value'] !== 'all') {
              deleteDoc(
                doc(
                  db,
                  `Communities/${currentUser.community_id}/Users/${user['value']}/Documents/${params.id}`
                )
              )
            }
          })
        }

        console.log(url)

        setDoc(
          doc(
            db,
            `Communities/${currentUser.community_id}/Administrador/data/Documents/`,
            params.id
          ),
          {
            title: values.title,
            category_id: values.category_id,
            users: usersArray,
            isPublic: values.users[0]['value'] === 'all' ? true : false,
            document: url,
            date: serverTimestamp(),
          },
          {merge: true}
        ).then(() => {
          //ADD DOCUMENTS TO ALL USERS
          if (values.users[0]['value'] === 'all') {
            console.log("entro 1")

            usersList.forEach(async (document) => {
              await setDoc(
                doc(
                  db,
                  'Communities',
                  currentUser.community_id,
                  'Users',
                  document.value,
                  'Documents',
                  params.id
                ),
                {
                  title: values.title,
                  category_id: values.category_id,
                  name: document.name,
                  document: url,
                  date: serverTimestamp(),
                },
              )
            })
          } else {
            //ADD DOCUMENT TO SELECTED USERS
            values.users.forEach((user) => {
              console.log("entro")
              setDoc(
                doc(
                  db,
                  'Communities',
                  currentUser.community_id,
                  'Users',
                  user['value'],
                  'Documents',
                  params.id
                ),
                {
                  date: serverTimestamp(),
                  title: values.title,
                  category_id: values.category_id,
                  name: document.name,
                  document: url,
                },
              )
            })
          }
        })

        setLoading(false)
        //navigate('/documents')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar Informaciones'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar Informaciones</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Usuario
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <Select
                  isMulti
                  onChange={(option) => formik.setFieldValue('users', option)}
                  closeMenuOnSelect={false}
                  options={usersList}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Categoría
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='category_id'
                  className='form-select form-select-solid'
                  {...formik.getFieldProps('category_id')}
                >
                  <option value=''>Seleccionar categoría..</option>
                  {categoriesList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
                {formik.touched.category_id && formik.errors.category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.category_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Documento</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  name='document'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Documento'
                  accept='pdf/*'
                  onChange={(e) => formik.setFieldValue('document', e.currentTarget.files[0])}
                />

                {document.document && (
                  <a
                    href={document.document}
                    className='btn btn-danger mt-3'
                    target='_blank'
                    rel='noopener noreferrer'
                    download={document.name}
                  >
                    Descargar documento
                  </a>
                )}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Modificar documento'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditDocument}
