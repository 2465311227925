/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom'
import {Button, Card} from 'react-bootstrap'
import {toast} from 'react-toastify'
import GetusersList from '../../services/getusersList'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título obligatorio'),
  question: Yup.string().required('Pregunta obligatoria'),
  init_date: Yup.string().required('Fecha inicio obligatoria'),
  limit_date: Yup.string().required('Fecha fin obligatoria'),
})

const EditSurvey: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  const intl = useIntl()

  interface IdSurveyDetails {
    title: string
    question: string
    init_date: string
    limit_date: string
    public_result: boolean
    options: []
  }

  const params = useParams()
  const [options, setOptions] = useState([])
  const [survey, setsurvey] = useState([] as any)
  const [usersList, setusersList] = useState([] as any)
  const [started, setStarted] = useState(false)

  useEffect(() => {
    GetusersList()
  }, [])

  const addNewOption = () => {
    if (options.length >= 5) {
      console.log('entro')
      toast.error('You can only add a maximum of 5 options')
      return
    }
    const newOptions = [...options, '']
    setOptions(newOptions)
  }

  const deleteOption = (index) => {
    if (options.length === 1) {
      toast.error('You need at least one option')
      return
    }
    const newOptions = [...options]
    newOptions.splice(index, 1)
    setOptions(newOptions)
  }

  const handleOptionsChange = (e, index) => {
    const {value} = e.target
    const newOptions = [...options]
    newOptions[index] = value
    setOptions(newOptions)
  }

  //Get info survey
  useEffect(() => {
    const getsurvey = async () => {
      try {
        const docRef = await doc(
          db,
          'Communities',
          currentUser.community_id,
          'Administrador',
          'data',
          'Surveys',
          params.id
        )
        const docSnap = await getDoc(docRef)

        const survey = docSnap.data()
        setsurvey(survey)
        setOptions(survey?.option)

        survey.init_date?.toDate().getTime() - new Date().getTime() > 0
          ? setStarted(false)
          : setStarted(true)
      } catch (err) {
        console.error(err)
      }
    }

    getsurvey()
  }, [])

  const initialValues: IdSurveyDetails = {
    title: survey.title,
    question: survey.question,
    init_date: survey.init_date?.toDate().toISOString().slice(0, 10),
    limit_date: survey.limit_date?.toDate().toISOString().slice(0, 10),
    public_result: survey.public_result,
    options: survey.options,
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdSurveyDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        setDoc(
          doc(db, `Communities/${currentUser.community_id}/Administrador/data/Surveys/`, params.id),
          {
            public_result: Boolean(values.public_result),
            title: values.title,
            question: values.question,
            init_date: new Date(values.init_date),
            limit_date: new Date(values.limit_date),
            option: options,
          },
          {merge: true}
        ).then(() => {
          usersList.forEach((user) => {
            setDoc(
              doc(
                db,
                'Communities',
                currentUser.community_id,
                'Users',
                user.id,
                'Surveys',
                params.id
              ),
              {
                public_result: Boolean(values.public_result),
                title: values.title,
                question: values.question,
                init_date: new Date(values.init_date),
                limit_date: new Date(values.limit_date),
                option: options,
              },
              {merge: true}
            )
          })
        })

        setLoading(false)
        navigate('/surveys')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar Encuestas'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar Encuesta</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Resultado público
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  disabled={started}
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  {...formik.getFieldProps('public_result')}
                >
                  <option value='false'>No</option>
                  <option value='true' selected>
                    Si
                  </option>
                </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Fecha Inicio
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fecha Inicio'
                  {...formik.getFieldProps('init_date')}
                />
                {formik.touched.init_date && formik.errors.init_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.init_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Fecha Fin
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fecha Fin'
                  {...formik.getFieldProps('limit_date')}
                />
                {formik.touched.limit_date && formik.errors.limit_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.limit_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  disabled={started}
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />

                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Pregunta</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  disabled={started}
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={formik.handleChange}
                  {...formik.getFieldProps('question')}
                ></textarea>
              </div>
            </div>

            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Opciones</span>
            </h3>

            <div>
              <Card>
                {options.map((op, index) => {
                  return (
                    <div key={index} className='d-flex'>
                      <input
                        disabled={started}
                        className='form-control form-control-lg form-control-solid w-400px my-5 ml-0'
                        placeholder='Opción'
                        value={op}
                        onChange={(e) => handleOptionsChange(e, index)}
                      />

                      <Button
                        className='mx-3 my-5'
                        disabled={started}
                        onClick={() => deleteOption(index)}
                      >
                        Eliminar
                      </Button>
                    </div>
                  )
                })}
                <Button
                  className='w-200px btn-secondary my-3 mb-5'
                  disabled={started}
                  onClick={addNewOption}
                >
                  Añadir opción
                </Button>
              </Card>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {survey.init_date?.toDate().getTime() - new Date().getTime() > 0 && (
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Modificar encuesta'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Guardando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
              {started && (
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={goBack}
                    >
                      Volver
                    </button>
                  )}
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditSurvey}
