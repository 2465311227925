/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import {doc, getDoc, setDoc} from 'firebase/firestore'
import {useNavigate, useParams} from 'react-router-dom'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título obligatorio'),
})

const EditCategoryDocuments: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  interface DocumentDetails {
    title: string
  }

  const [category, setcategory] = useState([] as any)
  const params = useParams()

  useEffect(() => {
    const getcategory = async () => {
      try {
        const docRef = doc(
          db,
          'Communities',
          currentUser.community_id,
          'CategoriesDocument',
          params.id
        )
        const docSnap = await getDoc(docRef)
        const category = docSnap.data()
        setcategory(category)
      } catch (err) {
        console.error(err)
      }
    }

    getcategory()
  }, [])

  const initialValues: DocumentDetails = {
    title: category.title,
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<DocumentDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      setTimeout(() => {
        setDoc(
          doc(db, `Communities/${currentUser.community_id}/CategoriesDocument/`, params.id),
          {
            title: values.title,
          },
          {merge: true}
        )

        setLoading(false)
        navigate('/documents/categories')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar Categoría'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar Categoría</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  {...formik.getFieldProps('title')}
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Actualizar Categoría'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditCategoryDocuments}
