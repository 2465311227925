/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom'
import {Button, Card} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetusersList from '../../services/getusersList'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título obligatorio'),
  question: Yup.string().required('Pregunta obligatoria'),
  init_date: Yup.string().required('Fecha inicio obligatoria'),
  limit_date: Yup.string().required('Fecha fin obligatoria'),
})

const NewSurvey: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [options, setOptions] = useState([])
  const [questionTitle, setQuestionTitle] = useState('')

  const addNewOption = () => {
    if (options.length >= 5) {
      console.log("entro")
      toast.error('You can only add a maximum of 5 options')
      return
    }
    const newOptions = [...options, '']
    setOptions(newOptions)
  }

  const deleteOption = (index) => {
    if (options.length === 1) {
      toast.error('You need at least one option')
      return
    }
    const newOptions = [...options]
    newOptions.splice(index, 1)
    setOptions(newOptions)
  }

  const handleQuestionTitleChange = (e) => {
    const {value} = e.target
    setQuestionTitle(value)
  }

  const handleOptionsChange = (e, index) => {
    const {value} = e.target
    const newOptions = [...options]
    newOptions[index] = value
    setOptions(newOptions)
  }

  const [usersList, setusersList] = useState([] as any)

  useEffect(() => {
    GetusersList()
  }, [])

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      title: '',
      public_result: '',
      user: '',
      question: '',
      init_date: '',
      limit_date: '',
      options: []
    },
    validationSchema: docDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)

      setTimeout(() => {
        addDoc(
          collection(
            db,
            'Communities',
            currentUser.community_id,
            'Administrador',
            'data',
            'Surveys'
          ),
          {
            public_result: Boolean(values.public_result),
            title: values.title,
            question: values.question,
            isVoted: -1,
            init_date: new Date(values.init_date),
            limit_date: new Date(values.limit_date),
            option: options
          }
        ).then((docRef) => {
          console.log(docRef.id)

          usersList.forEach((user) => {
            setDoc(
              doc(
                db,
                'Communities',
                currentUser.community_id,
                'Users',
                user.id,
                'Surveys',
                docRef.id
              ),
              {
                public_result: Boolean(values.public_result),
                title: values.title,
                question: values.question,
                isVoted: -1,
                init_date: new Date(values.init_date),
                limit_date: new Date(values.limit_date),
                option: options

              }
            )
          })
        })

        setLoading(false)
        navigate('/surveys')
      }, 1000)
    },
  })

  return (
    <>
    <ToastContainer />
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Encuesta'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Encuesta</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Resultado público
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='public_result'
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.public_result}
                >
                  <option value='false'>No</option>
                  <option value='true' selected>
                    Si
                  </option>
                </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Fecha Inicio
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='date'
                  name='init_date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fecha Inicio'
                  onChange={formik.handleChange}
                  value={formik.values.init_date}
                />
                {formik.touched.init_date && formik.errors.init_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.init_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Fecha Fin
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='date'
                  name='limit_date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fecha Fin'
                  onChange={formik.handleChange}
                  value={formik.values.limit_date}
                />
                {formik.touched.limit_date && formik.errors.limit_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.limit_date}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Pregunta</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  name='question'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={formik.handleChange}
                  value={formik.values.question}
                ></textarea>
              </div>
            </div>

              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Opciones</span>
              </h3>

            <div>
              <Card>
                {options.map((op, index) => {
                  return (
                    <div key={index} className='d-flex'>
                      <input
                        className='form-control form-control-lg form-control-solid w-400px my-5 ml-0'
                        placeholder='Opción'
                        value={op}
                        onChange={(e) => handleOptionsChange(e, index)}
                      />

                      <Button className='mx-3 my-5' onClick={() => deleteOption(index)}>
                        Eliminar
                      </Button>
                    </div>
                  )
                })}
                <Button className='w-200px btn-secondary my-3 mb-5' onClick={addNewOption}>
                  Añadir opción
                </Button>
              </Card>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir encuesta'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewSurvey}
