import {Link} from 'react-router-dom';
import {KTSVG} from '../../../_metronic/helpers';

const FilterComponent = (
  {filterText, onFilter, onClear} // <> //   <Input //     id="search"
) => {
  return (
    <div className="card-header border-0 p-0 m-0 w-100">
      <div className="card-title">
        {/* begin::Search */}
        <div className="d-flex align-items-center position-relative my-1">
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-1 position-absolute ms-6"
          />
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-250px ps-14"
            placeholder="Filtrar..."
            onChange={onFilter}
          />
        </div>
        {/* end::Search */}
      </div>

      {/* begin::Card toolbar */}
      <div className="card-toolbar">
        <Link
          to="/communities/create"
          className="btn btn-primary align-self-center"
        >
          Añadir comunidad
        </Link>
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export default FilterComponent;
