/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../helpers'

const SidebarFooter = () => {
  return (
    <>
    </>
  )
}

export {SidebarFooter}
