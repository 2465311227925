import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db} from '../../config/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom'

const docDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Nombre obligatorio'),
  municipality: Yup.string().required('Población obligatorio'),
  postal_code: Yup.string().required('Código postal obligatorio'),
  province: Yup.string().required('Provincia obligatorio'),
})

const EditCommunity: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  interface IdCommunityDetails {
    name: string
    municipality: string
    postal_code: string
    province: string
  }

  const [community, setcommunity] = useState([] as any)

  const params = useParams()

  //Get info community
  useEffect(() => {
    getcommunity()
  }, [])

  const getcommunity = async () => {
    try {
      const docRef = await doc(db, 'Communities', params.id)
      const docSnap = await getDoc(docRef)

      const community = docSnap.data()
      setcommunity(community)
    } catch (err) {
      console.error(err)
    }
  }

  const initialValues: IdCommunityDetails = {
    name: community.community_info?.name || '',
    municipality: community.community_info?.municipality || '',
    postal_code: community.community_info?.postal_code || '',
    province: community.community_info?.province || '',
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IdCommunityDetails>({
    enableReinitialize: true,
    initialValues,
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      setTimeout(() => {
        setDoc(
          doc(db, `Communities/`, params.id),
          {
            community_info: {
              name: values.name,
              municipality: values.municipality,
              postal_code: values.postal_code,
              province: values.province,
            },
          },
          {merge: true}
        )

        setLoading(false)
        navigate('/communities')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Modificar comunidad'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Modificar comunidad</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Nombre
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nombre'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Población
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Población'
                  {...formik.getFieldProps('municipality')}
                />
                {formik.touched.municipality && formik.errors.municipality && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.municipality}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Código postal
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Código postal'
                  {...formik.getFieldProps('postal_code')}
                />
                {formik.touched.postal_code && formik.errors.postal_code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.postal_code}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Provincia
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Provincia'
                  {...formik.getFieldProps('province')}
                />
                {formik.touched.province && formik.errors.province && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.province}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Actualizar Comunidad'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {EditCommunity}
