import React, { useMemo } from 'react';

import DataTable from 'react-data-table-component'
import FilterComponent from './FilterComponent.js'

const Table = (props) => {
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    table: {
        style:{
            backgroundColor: "red"
        }
    },
    headCells: {
      style: {
        fontSize: '14px', // override the cell padding for head cells
        fontWeight: '600',
        color: '#a1a5b7'
      },
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.name,
      cell: (row) => (
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {row.name}
        </a>
      ),
      sortable: true,
    },
    {
      name: 'E-mail',
      selector: (row) => row.id,
      cell: (row) => (
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {row.id}
        </a>
      ),
      sortable: true,
    },
  ]

  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    (item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  )

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      customStyles={customStyles}
      paginationComponentOptions={paginationComponentOptions}
    />
  )
}

export default Table
