import { useEffect, useState } from 'react';
import {KTSVG} from '../../../_metronic/helpers';
import {useAuth} from '../../modules/auth';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../config/firebase';

const FilterComponent = (
  {filterText, onFilter, onClear} // <> //   <Input //     id="search"
) => {
  const {currentUser} = useAuth ();

  const [usersList, setusersList] = useState ([]);

  useEffect (() => {
    const getusersList = async () => {
      try {
        await getDocs (
          collection (db, 'Communities', currentUser.community_id, 'Users')
        ).then (querySnapshot => {
          const newData = querySnapshot.docs.map (doc => ({
            ...doc.data (),
            id: doc.id,
          }));
          setusersList (newData);
        });
      } catch (err) {
        console.error (err);
      }
    };

    getusersList ();
  }, []);

  return (
    <div className="card-header border-0 p-0 m-0 w-100">
      <div className="card-title">
        {/* begin::Search */}
        <div className="d-flex align-items-center position-relative my-1">
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-1 position-absolute ms-6"
          />
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-250px ps-14"
            placeholder="Filtrar..."
            onChange={onFilter}
          />
        </div>
        {/* end::Search */}
        <div className="mx-5">
          <select
            name="user"
            className="form-select form-select-solid w-350px"
            onChange={onFilter}
          >
            <option value="">Seleccionar usuario..</option>
            {usersList.map (user => (
              <option key={user.id} value={user.id}>
                {user.personal_information.name}
                {' '}
                {user.personal_information.surname}
              </option>
            ))}
          </select>
        </div>
      </div>

    </div>
  );
};

export default FilterComponent;
