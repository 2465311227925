/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {auth, db} from '../../config/firebase'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom'
import {createUserWithEmailAndPassword} from 'firebase/auth'

const userDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Nombre obligatorio'),
  surname: Yup.string().required('Apellidos obligatorio'),
  email: Yup.string().required('E-mail obligatorio'),
  phone: Yup.string().required('Teléfono obligatorio'),
  password: Yup.string()
    .min(6, 'Mínimo 6 caracatres')
    .max(50, 'Máximo 50 caracteres')
    .required('Password obligatorio'),
  passwordConfirmation: Yup.string()
    .min(6, 'Mínimo 6 caracatres')
    .max(50, 'Máximo 50 caracteres')
    .required('Password obligatorio')
    .oneOf([Yup.ref('password'), null], 'Los passwords no coinciden'),
})

const NewUser: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [surveysList, setsurveysList] = useState([] as any)
  const [documentsList, setdocumentsList] = useState([] as any)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getsurveysList = async () => {
      try {

        const q = query(collection(
          db,
          'Communities',
          currentUser.community_id,
          'Administrador',
          'data',
          'Surveys'
        ), where("limit_date", ">=", new Date()));
        await getDocs(
          q
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setsurveysList(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getsurveysList()
  }, [])

  useEffect(() => {
    const getdocumentsList = async () => {
      try {
        const q = query(collection(
          db,
          'Communities',
          currentUser.community_id,
          'Administrador',
          'data',
          'Documents'
        ), where("isPublic", "==", true));

        await getDocs(
          q
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
          setdocumentsList(newData)
        })
      } catch (err) {
        console.error(err)
      }
    }

    getdocumentsList()
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      building: '',
      door: '',
      floor: '',
      stair: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((res) => {
          const data = {
            email: values.email,
            name: values.name,
            surname: values.surname,
            phone: values.phone,
            creation_date: new Date().toUTCString(),
            house: {
              building: values.building,
              door: values.door,
              floor: values.floor,
              stair: values.stair,
            },
          }
          setLoading(true)
          setTimeout(() => {
            setDoc(doc(db, 'Communities', currentUser.community_id, 'Users', values.email), {
              personal_information: data,
            })
            setDoc(doc(db, 'Users', values.email), {
              community_id: currentUser.community_id,
              uid: res.user.uid
            })

            surveysList.forEach(async (document) => {
                await setDoc(
                  doc(
                    db,
                    'Communities',
                    currentUser.community_id,
                    'Users',
                    values.email,
                    'Surveys',
                    document.id
                  ),
                  {
                    title: document.title,
                    question: document.question,
                    isRead: false,
                    init_date: document.init_date,
                    limit_date: document.limit_date,
                  }
                )
            })

            documentsList.forEach(async (document) => {
                await setDoc(
                  doc(
                    db,
                    'Communities',
                    currentUser.community_id,
                    'Users',
                    values.email,
                    'Documents',
                    document.id
                  ),
                  {
                    title: document.title,
                    category_id: document.category_id,
                    name: document.name,
                    document: document.document,
                    date: document.date,
                  }
                )
            })

            setLoading(false)
           navigate('/users')
          }, 1000)
        })
        .catch((err) => console.error(err))
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Usuarios'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Usuario</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Nombre
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='name'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nombre'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Apellidos
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='surname'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Apellidos'
                  onChange={formik.handleChange}
                  value={formik.values.surname}
                />
                {formik.touched.surname && formik.errors.surname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.surname}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Teléfono
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='tel'
                  name='phone'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Teléfono'
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                E-mail
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='email'
                  name='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='E-mail'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Password
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='password'
                  name='password'
                  id='password'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Pasword'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Confirmar Password
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='password'
                  id='confirmpassword'
                  name='confirmpassword'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Confirmar Password'
                  {...formik.getFieldProps('passwordConfirmation')}
                />

                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Edificio</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='building'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Edificio'
                  onChange={formik.handleChange}
                  value={formik.values.building}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Planta</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='floor'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Planta'
                  onChange={formik.handleChange}
                  value={formik.values.floor}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Escalera</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='stair'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Escalera'
                  onChange={formik.handleChange}
                  value={formik.values.stair}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Puerta</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='door'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Puerta'
                  onChange={formik.handleChange}
                  value={formik.values.door}
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir Usuario'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewUser}
