import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {UsersWrapper} from '../pages/users/UsersWrapper'
import {PaymentsWrapper} from '../pages/payments/PaymentsWrapper'
import {NewPayment} from '../pages/payments/NewPayment'
import {EditPayment} from '../pages/payments/EditPayment'
import {IncidentsWrapper} from '../pages/incidents/IncidentsWrapper'
import {InformationWrapper} from '../pages/information/InformationWrapper'
import {NewUser} from '../pages/users/NewUser'
import {CategoriesInformationWrapper} from '../pages/information/CategoriesInformationWrapper'
import {NewCategoryInformation} from '../pages/information/NewCategoryInformation'
import {NewInformation} from '../pages/information/NewInformation'
import {NoticesWrapper} from '../pages/notices/NoticeWrapper'
import {NewNotice} from '../pages/notices/NewNotice'
import {EditNotice} from '../pages/notices/EditNotice'
import {SurveysWrapper} from '../pages/surveys/SurveyWrapper'
import {NewSurvey} from '../pages/surveys/NewSurvey'
import {EditSurvey} from '../pages/surveys/EditSurvey'
import {EditIncident} from '../pages/incidents/EditIncident'
import {EditInformation} from '../pages/information/EditInformation'
import {CategoriesDocumentsWrapper} from '../pages/documents/CategoriesDocumentsWrapper'
import {NewCategoryDocument} from '../pages/documents/NewCategoryDocument'
import {DocumentsWrapper} from '../pages/documents/DocumentsWrapper'
import {EditDocument} from '../pages/documents/EditDocument'
import {NewDocument} from '../pages/documents/NewDocument'
import {EditUser} from '../pages/users/EditUser'
import { EditCategoryInformation } from '../pages/information/EditCategoryInformation'
import { EditCategoryDocuments } from '../pages/documents/EditCategoryDocuments'
import { NewAdmin } from '../pages/admins/NewAdmin'
import { AdminWrapper } from '../pages/admins/AdminsWrapper'
import { NewCommunity } from '../pages/communities/NewCommunity'
import { EditCommunity } from '../pages/communities/EditCommunity'
import { CommunitiesWrapper } from '../pages/communities/CommunitiesWrapper'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {

  const {currentUser,logout} = useAuth()


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {currentUser.roles === 1 && (<Route path='auth/*' element={<Navigate to='/communities' />} />)}
        {currentUser.roles === 0 && (<Route path='auth/*' element={<Navigate to='/incidents' />} />)}
        {/* Pages */}
        <Route path='information' element={<InformationWrapper />} />
        <Route path='information/edit/:id' element={<EditInformation />} />
        <Route path='information/categories' element={<CategoriesInformationWrapper />} />
        <Route path='information/create' element={<NewInformation />} />
        <Route path='information/categories/create' element={<NewCategoryInformation />} />
        <Route path='information/categories/edit/:id' element={<EditCategoryInformation />} />
        <Route path='incidents' element={<IncidentsWrapper />} />
        <Route path='incidents/edit/:id' element={<EditIncident />} />
        <Route path='users' element={<UsersWrapper />} />
        <Route path='users/create' element={<NewUser />} />
        <Route path='users/edit/:id' element={<EditUser />} />
        <Route path='payments' element={<PaymentsWrapper />} />
        <Route path='payments/create' element={<NewPayment />} />
        <Route path='payments/edit/:user/:id' element={<EditPayment />} />
        <Route path='notices' element={<NoticesWrapper />} />
        <Route path='notices/create' element={<NewNotice />} />
        <Route path='notices/edit/:user/:id' element={<EditNotice />} />

        <Route path='documents' element={<DocumentsWrapper />} />
        <Route path='documents/edit/:id' element={<EditDocument />} />
        <Route path='documents/create' element={<NewDocument />} />
        <Route path='documents/categories' element={<CategoriesDocumentsWrapper />} />
        <Route path='documents/categories/create' element={<NewCategoryDocument />} />
        <Route path='documents/categories/edit/:id' element={<EditCategoryDocuments />} />

        <Route path='surveys' element={<SurveysWrapper />} />
        <Route path='surveys/create' element={<NewSurvey />} />
        <Route path='surveys/edit/:id' element={<EditSurvey />} />

        <Route path='communities' element={<CommunitiesWrapper />} />
        <Route path='communities/create' element={<NewCommunity />} />
        <Route path='communities/edit/:id' element={<EditCommunity />} />

        <Route path='admins' element={<AdminWrapper />} />
        <Route path='admins/create' element={<NewAdmin />} />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
