/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {useAuth} from '../../modules/auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {db, storage} from '../../config/firebase'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom'
import {ref} from 'firebase/storage'
import doFileUpload from '../../components/UploadFileComponent.js'
import GetusersList from '../../services/getusersList'

const docDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Título del pago obligatorio'),
  user: Yup.string().required('Usuario obligatorio'),
})



const NewNotice: React.FC = () => {
  const {currentUser,logout} = useAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  const [usersList, setusersList] = useState([] as any)

  useEffect(() => {
    GetusersList()
  }, [])

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      title: '',
      user: '',
      description: '',
      image: null,
      video: null,
      document: null,
    },
    validationSchema: docDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      const images = []
      if (values.image) {
        for (const imageFile of values.image) {
          const storageRefImages = ref(
            storage,
            `/${currentUser.community_id}/media/image/${imageFile.name}`
          )
          images.push(await doFileUpload(storageRefImages, imageFile))
        }
      }

      const videos = []
      if (values.video) {
        for (const videoFile of values.video) {
          const storageRefVideos = ref(
            storage,
            `/${currentUser.community_id}/media/video/${videoFile.name}`
          )
          videos.push(await doFileUpload(storageRefVideos, videoFile))
        }
      }

      const documents = {}
      if (values.document) {
        for (const documentFile of values.document) {
          const storageRefDocuments = ref(
            storage,
            `/${currentUser.community_id}/document/${documentFile.name}`
          )
          const fileUrl = await doFileUpload(storageRefDocuments, documentFile)
          documents[documentFile.name] = fileUrl
        }
      }

      setTimeout(() => {
        addDoc(
          collection(db, 'Communities', currentUser.community_id, 'Users', values.user, 'Notices'),
          {
            title: values.title,
            description: values.description,
            isRead: false,
            date: serverTimestamp(),
            document: documents,
            media: {
              image: images,
              video: videos,
            },
          }
        )

        setLoading(false)
        navigate('/notices')
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Añadir Notificación'})}</PageTitle>

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Añadir Notificación</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Usuario
              </label>

              <div className='col-lg-8 col-xl-6 fv-row'>
                <select
                  name='user'
                  className='form-select form-select-solid'
                  onChange={formik.handleChange}
                  value={formik.values.user}
                >
                  <option value=''>Seleccionar usuario..</option>
                  {usersList.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.personal_information.name} {user.personal_information.surname}
                    </option>
                  ))}
                </select>
                {formik.touched.user && formik.errors.user && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.user}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label required fw-bold fs-6'>
                Título
              </label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Título'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Descripción</label>

              <div className='col-lg-8 col-xl-6'>
                <textarea
                  name='description'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={formik.handleChange}
                  value={formik.values.description}
                ></textarea>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Imagen</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='image'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Imagen'
                  accept='image/*'
                  onChange={(e) => formik.setFieldValue('image', e.currentTarget.files)}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Vídeo</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='video'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Vídeo'
                  accept='video/*'
                  onChange={(e) => formik.setFieldValue('video', e.currentTarget.files)}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-xl-1 col-form-label fw-bold fs-6'>Documentos</label>

              <div className='col-lg-8 col-xl-6'>
                <input
                  type='file'
                  multiple
                  name='document'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Vídeo'
                  accept='file/pdf,file/xls,file/xlsx,file/docx,file/doc'
                  onChange={(e) => formik.setFieldValue('document', e.currentTarget.files)}
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Añadir notificación'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {NewNotice}
